<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-26 11:35:40
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-01 15:29:08
 * @FilePath: /mediatom-web/src/components/AccountModal/PlatForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="plat-account-form-container">
    <a-form-model-item v-if="showKey(platId)" prop="key" :label="getKeyLabel(platId)">
      <a-radio-group v-if="+platId === 1061" name="radioGroup" v-model="formquery.key">
        <a-radio value="JSON"> JSON </a-radio>
        <a-radio value="PROTO"> PROTO </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="showEncryptType(platId)" prop="encryptType" :label="getEncryptTypeLabel(platId)">
      <a-radio-group v-if="+platId === 1061" name="radioGroup" v-model="formquery.encryptType">
        <a-radio value="ECB"> ECB </a-radio>
        <a-radio value="CBC"> CBC </a-radio>
      </a-radio-group>
      <a-radio-group v-else-if="+platId === 1083" name="radioGroup" v-model="formquery.encryptType">
        <a-radio value="1"> 是 </a-radio>
        <a-radio value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="showEncryptParameter(platId)" prop="encryptParameter" :label="getEncryptParameterLabel(platId)">
      <a-radio-group v-if="+platId === 1061" name="radioGroup" v-model="formquery.encryptParameter">
        <a-radio value="128"> 128 </a-radio>
        <a-radio value="192"> 192 </a-radio>
        <a-radio value="256"> 256 </a-radio>
      </a-radio-group>
      <a-radio-group v-else-if="+platId === 1083" name="radioGroup" v-model="formquery.encryptParameter">
        <a-radio value="1"> 是 </a-radio>
        <a-radio value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="showCodingType(platId)" prop="codingType" :label="getCodingTypeLabel(platId)">
      <a-radio-group v-if="+platId === 1061" name="radioGroup" v-model="formquery.codingType">
        <a-radio value="Base64"> Base64 </a-radio>
        <a-radio value="Hex"> Hex </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 巨浪、美团独有 -->
    <!-- dspDeviceSwitch -->
    <a-form-model-item
      v-if="+platId === 1046 ||+platId === 1006"
      label="设备冷却"
      prop="dspDeviceSwitch"
    >
      <a-radio-group name="radioGroup" v-model="formquery.dspDeviceSwitch">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getKeyLabel,
  showKey,
  getEncryptTypeLabel,
  showEncryptType,
  getEncryptParameterLabel,
  showEncryptParameter,
  getCodingTypeLabel,
  showCodingType
} from '@/utils/autoLabel'
export default {
  data () {
    return {
      formqueryTemp: {
        key: undefined,
        encryptType: undefined,
        encryptParameter: undefined,
        codingType: undefined,
        dspDeviceSwitch: 0
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  created () {
    this.$set(this.formquery, 'key', this.query.key)
    this.$set(this.formquery, 'encryptType', this.query.encryptType)
    this.$set(this.formquery, 'encryptParameter', this.query.encryptParameter)
    this.$set(this.formquery, 'codingType', this.query.codingType)
    this.$set(this.formquery, 'dspDeviceSwitch', this.query.dspDeviceSwitch || 0)
  },
  props: {
    query: {
      default: () => ({
      }),
      type: Object
    },
    platId: {
      default: 0,
      type: [Number, String]
    }
  },
  methods: {
    getKeyLabel,
    showKey,
    getEncryptTypeLabel,
    showEncryptType,
    getEncryptParameterLabel,
    showEncryptParameter,
    getCodingTypeLabel,
    showCodingType
  }
}
</script>

<style lang="less" scoped></style>
